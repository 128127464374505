import 'bootstrap/dist/css/bootstrap.min.css';
import 'react-app-polyfill/stable';
import 'react-app-polyfill/ie11';
import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
// import * as serviceWorker from './serviceWorker';
import * as Sentry from '@sentry/browser';


if (process.env.NODE_ENV && process.env.NODE_ENV !== 'development') {
  //console.log(process.env.REACT_APP_RELEASE_VERSION)
  Sentry.init({
    dsn: "https://b51ffb426eeb48608beacd68b8c0197b@o400752.ingest.sentry.io/5259434", 
    release:process.env.REACT_APP_RELEASE_VERSION,
    ignoreErrors:["/.*ChunkLoadError.*/gm", "/.*Loading chunk.*failed/gm"]
  });
}
let vh = window.innerHeight * 0.01;
// Then we set the value in the --vh custom property to the root of the document
document.documentElement.style.setProperty('--vh', `${vh}px`);
window.addEventListener('resize', () => {
  // We execute the same script as before
  let vh = window.innerHeight * 0.01;
  document.documentElement.style.setProperty('--vh', `${vh}px`);
});
ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
// serviceWorker.register();
