/* eslint-disable no-useless-escape */
import React, { Component, Suspense, lazy, Fragment } from "react";
import { BrowserRouter as Router, Switch, Route, Redirect } from "react-router-dom";

import Spinner from "react-bootstrap/Spinner";
import Toast from "react-bootstrap/Toast";
import TagManager from "react-gtm-module";

import * as serviceWorker from "./serviceWorker";
import "./App.css";

const tagManagerArgs = { gtmId: "GTM-PF4DHTQ" };
TagManager.initialize(tagManagerArgs);

const Navbar = lazy(() =>
  componentLoader(() => import("./Components/Navbar/Navbar"), 5)
);

const FullPageHomeComponent = lazy(() =>
  componentLoader(() => import("./Views/Home/FullPageHome"), 5)
);

const Footer = lazy(() =>
  componentLoader(() => import("./Components/Footer/Footer"), 5)
);

const AceptarTerminos = lazy(() =>
  componentLoader(() => import("./Views/Home/AceptarTerminos/AceptarTerminos"), 5)
);

const AceptarTerminosFromAPI = lazy(() =>
  componentLoader(() => import("./Views/Home/AceptarTerminos/AceptarTerminosFromAPI"), 5)
);

const InfoPromo = lazy(() =>
  componentLoader(() => import("./Views/Home/AceptarTerminos/InfoPromo"), 5)
);

const Instrucciones = lazy(() =>
  componentLoader(() => import("./Views/Instrucciones/Instrucciones"), 5)
);

const DatosDeContacto = lazy(() =>
  componentLoader(() => import("./Views/DatosDeContacto/DatosDeContacto"), 5)
);

const Sidebar = lazy(() =>
  componentLoader(() => import("./Components/Sidebar/Sidebar"), 5)
);

const SidebarAdmin = lazy(() =>
  componentLoader(() => import("./Components/Sidebar/SidebarAdmin"), 5)
);

const Confirmar = lazy(() =>
  componentLoader(() => import("./Views/ConfirmarCuenta/Confirmar"), 5)
);

const CuentaAdminV = lazy(() =>
  componentLoader(() => import("./Views/CuentaAdmin_v2/CuentaAdminV"), 5)
);

const ActualizaNavegador = lazy(() =>
  componentLoader(() => import("./Views/Home/ActualizaNavegador"), 5)
);

const FAQs = lazy(() => 
  componentLoader(() => import("./Views/FAQs/FAQs"), 5)
);

const TerminosDeServicio = lazy(() =>
  componentLoader(() => import("./Views/TerminosDeServicio/TerminosDeServicio"), 5)
);

const PoliticaDePrivacidad = lazy(() =>
  componentLoader(() => import("./Views/PoliticaDePrivacidad/PoliticaDePrivacidad"), 5)
);

const Encuesta = lazy(() =>
  componentLoader(() => import("./Views/Home/Encuesta/Encuesta"), 5)
);

export default class App extends Component {
  constructor(props) {
    super(props);
    this.state = {
      supported_navigator: this.supportedNavigator(),
      newVersionAvailable: false,
      waitingWorker: {},
      hayCambios: false,
    };
  }

  mostrarToast() {
    return (
      <div>
        <Toast style={{
            position: "absolute",
            top: "12vh",
            right: 0,
            zIndex: 2,
          }} >
          <Toast.Header>
            <strong className="mr-auto">Atención</strong>
          </Toast.Header>
          <Toast.Body>
            <div>
              Hay cambios en el portal que posiblemente no estés observando, por
              favor actualiza la página.
              <br />
              <button className="newBtn-base" size="small" onClick={this.updateServiceWorker} >
                Actualizar página
              </button>
            </div>
          </Toast.Body>
        </Toast>
      </div>
    );
  }

  onServiceWorkerUpdate = (registration) => {
    this.setState({
      waitingWorker: registration && registration.waiting,
      newVersionAvailable: true,
    });
  };

  updateServiceWorker = () => {
    const { waitingWorker } = this.state;
    waitingWorker && waitingWorker.postMessage({ type: "SKIP_WAITING" });
    this.setState({ newVersionAvailable: false });
    window.location.reload();
  };

  refreshAction = (key) => {
    return (
      <Fragment>
        <button className="snackbar-button" size="small" onClick={this.updateServiceWorker}>
          {"refresh"}
        </button>
      </Fragment>
    );
  };

  componentDidMount = () => {
    if (process.env.NODE_ENV === "production") {
      serviceWorker.register({ onUpdate: this.onServiceWorkerUpdate });
    }

    if (this.state.newVersionAvailable) {
      //show snackbar with refresh button
      this.setState({ hayCambios: true });
    }

    window.dataLayer.push({ event: "pageview" });
  };

  supportedNavigator() {
    const browser = this.get_browser().name;
    const version = this.get_browser().version;
    if (window.location.href.indexOf("force") === -1) {
      if (!this.checkMobile()) {
        //Chrome >= 77
        if (browser === "Chrome" && version >= 76) {
          return true;
        }
        //Edge >= 79
        if (browser === "Edge" && version >= 79) {
          return true;
        }
        //Opera 18 && Edge 44
        if (browser === "Opera" && version >= 18) {
          return true;
        }
        //Safari>= 11
        if (browser === "Safari" && version >= 11) {
          return true;
        }
        //Firefox>= 71
        if (browser === "Firefox" && version >= 71) {
          return true;
        }
        //IE>= 11
        // if (browser === 'IE' && version >= 11) {
        //   return true;
        // }
        if (window.location.pathname !== "/nosoportado") {
          window.location.href = "/nosoportado";
        }
        return false;
      }
      return true;
    }
    return true;
  }

  get_browser() {
    var ua = navigator.userAgent, tem, M = ua.match( /(opera|chrome|safari|firefox|msie|trident(?=\/))\/?\s*(\d+)/i ) || [];
    if (/trident/i.test(M[1])) {
      tem = /\brv[ :]+(\d+)/g.exec(ua) || [];
      return { name: "IE", version: tem[1] || "" };
    }
    if (M[1] === "Chrome") {
      tem = ua.match(/\bOPR|Edge\/(\d+)/);
      if (tem != null) {
        return { name: "Opera", version: tem[1] };
      }
    }
    M = M[2] ? [M[1], M[2]] : [navigator.appName, navigator.appVersion, "-?"];
    if ((tem = ua.match(/version\/(\d+)/i)) != null) {
      M.splice(1, 1, tem[1]);
    }
    return {
      name: M[0],
      version: M[1],
    };
  }

  checkMobile() {
    var check = false;
    (function (a) {
      if (
        /(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|iris|kindle|lge |maemo|midp|mmp|mobile.+firefox|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows ce|xda|xiino/i.test(
          a
        ) ||
        /1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s\-)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|\-m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br(e|v)w|bumb|bw\-(n|u)|c55\/|capi|ccwa|cdm\-|cell|chtm|cldc|cmd\-|co(mp|nd)|craw|da(it|ll|ng)|dbte|dc\-s|devi|dica|dmob|do(c|p)o|ds(12|\-d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly(\-|_)|g1 u|g560|gene|gf\-5|g\-mo|go(\.w|od)|gr(ad|un)|haie|hcit|hd\-(m|p|t)|hei\-|hi(pt|ta)|hp( i|ip)|hs\-c|ht(c(\-| |_|a|g|p|s|t)|tp)|hu(aw|tc)|i\-(20|go|ma)|i230|iac( |\-|\/)|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja(t|v)a|jbro|jemu|jigs|kddi|keji|kgt( |\/)|klon|kpt |kwc\-|kyo(c|k)|le(no|xi)|lg( g|\/(k|l|u)|50|54|\-[a-w])|libw|lynx|m1\-w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|m\-cr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t(\-| |o|v)|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|n30(0|2)|n50(0|2|5)|n7(0(0|1)|10)|ne((c|m)\-|on|tf|wf|wg|wt)|nok(6|i)|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan(a|d|t)|pdxg|pg(13|\-([1-8]|c))|phil|pire|pl(ay|uc)|pn\-2|po(ck|rt|se)|prox|psio|pt\-g|qa\-a|qc(07|12|21|32|60|\-[2-7]|i\-)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h\-|oo|p\-)|sdk\/|se(c(\-|0|1)|47|mc|nd|ri)|sgh\-|shar|sie(\-|m)|sk\-0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h\-|v\-|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl\-|tdg\-|tel(i|m)|tim\-|t\-mo|to(pl|sh)|ts(70|m\-|m3|m5)|tx\-9|up(\.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|\-v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c(\-| )|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas\-|your|zeto|zte\-/i.test(
          a.substr(0, 4)
        )
      )
        check = true;
    })(navigator.userAgent || navigator.vendor || window.opera);
    return check;
  }

  render() {
    return (
      <div className="televia-container">
        <Suspense fallback={
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "center",
                alignItems: "center",
                height: "100vh",
              }}>
              <Spinner animation="border" style={{
                  color: "#fbca32",
                  margin: "auto",
                  display: "flex",
                  alignSelf: "center",
                }} >
                <span className="sr-only">Loading...</span>
              </Spinner>
            </div>
          } >
          {this.state.newVersionAvailable ? this.mostrarToast() : null}
          <Router>
            {/* <Navbar /> */}
            {this.state.supported_navigator ? (
              <Switch>
                <Route exact path="/">
                  <>
                    {/* <NavbarHome/> */}
                    <FullPageHomeComponent />
                  </>
                </Route>
                <Route exact path="/registro">
                  <FullPageHomeComponent registro={true} />
                </Route>                
                <Route exact path="/cierre-rewards-faqs">
                  <FullPageHomeComponent cierreRewards={true} />
                </Route>
                <Route path="/encuestas/:ClaveEncuesta/:ClaveSesion/:DesdeLogin?">
                  <Navbar />
                  <Encuesta />
                  <Footer />
                </Route>
                <Route exact path="/aceptar-terminos/:ClaveSocio">
                  <Navbar />
                  <AceptarTerminos />
                  <Footer />
                </Route>
                <Route exact path="/accept-terms">
                  <Navbar />
                  <AceptarTerminosFromAPI />
                  <Footer />
                </Route>
                <Route exact path="/aceptar-mision/:ClaveSesion/:ClaveMision">
                  <Navbar />
                  <InfoPromo />
                  <Footer />
                </Route>
                <Route path="/instrucciones">
                  <Navbar />
                  <Instrucciones />
                  <Footer />
                </Route>
                <Route path="/user">
                  <Navbar />
                  <Sidebar />
                  <Footer />
                </Route>
                <Route path="/administrador">
                  <Navbar />
                  <SidebarAdmin />
                  <Footer />
                </Route>
                <Route path="/callcenter">
                  <Navbar />
                  <CuentaAdminV />
                  <Footer />
                </Route>
                <Route path="/terminos-de-servicio">
                  <Navbar />
                  <TerminosDeServicio />
                  <Footer />
                </Route>
                <Route path="/politica-privacidad">
                  <Navbar />
                  <PoliticaDePrivacidad />
                  <Footer />
                </Route>
                <Route path="/preguntas-frecuentes">
                  <Navbar />
                  <FAQs />
                  <Footer />
                </Route>
                <Route path="/datos-contacto">
                  <Navbar />
                  <DatosDeContacto />
                  <Footer />
                </Route>
                <Route path="/confirmacion/:id">
                  <Navbar />
                  <Confirmar />
                  <Footer />
                </Route>
                <Redirect to="/" />
              </Switch>
            ) : (
              <ActualizaNavegador />
            )}
          </Router>
        </Suspense>
      </div>
    );
  }
}

// serviceWorker.register();
function componentLoader(lazyComponent, attemptsLeft) {
  return new Promise((resolve, reject) => {
    lazyComponent()
      .then(resolve)
      .catch((error) => {
        // let us retry after 1500 ms
        setTimeout(() => {
          if (attemptsLeft === 1) {
            reject(error);
            return;
          }
          componentLoader(lazyComponent, attemptsLeft - 1).then(
            resolve,
            reject
          );
        }, 500);
      });
  });
}
